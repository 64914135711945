<template>
  <header>
    <div class="banner" v-if="ENV.name != 'Production'">Money Sanity U <strong>{{ ENV.name }}</strong> Environment <span  v-if="user && isAdmin"> - <em>"{{settings.config}}"</em> display settings</span></div>
    <div class="bodySpacer">
      <div class="leftSide">
        <a href="#" @click.prevent="navigateTo('/dashboard')" class="mainLogo">
          <h1>Money Sanity U</h1>
        </a>
        <headerLogo v-if="group && imagePath && user" :imagePath="imagePath" :groupTitle="group.title" :linkTo="linkTo" ></headerLogo>
      </div>

      <nav class="userNav" v-if="user">
        <input type="checkbox" name="menuToggle" id="menuToggle" v-model="navOpen">
        <div v-if="showNav" class="links">
          <headerLogo v-if="group && imagePath && user" :imagePath="imagePath" :groupTitle="group.title" :linkTo="linkTo"></headerLogo>
          <router-link :to="'/AllTopics'">Topics</router-link>
          <router-link :to="{ name: 'UserProgress', params: {} }"  v-if="userProgress && userProgress.activeTracks.length > 0">My Progress</router-link>
          <router-link :to="'/AllTracks'">All Content</router-link>
          <router-link :to="'/admin/groups'" v-if="isAdmin">Admin</router-link>
          <router-link :to="{name: 'AdminGroup', params:{slug: group.slug}}"  v-if="isManager">Manage Users</router-link>
          <a href="#" v-on:click="signOut">Sign Out</a>
        </div>
      </nav>
    </div>
  </header>
</template>

<script>
import AmplifyStore from '../store/store'
import { Auth } from 'aws-amplify'
import headerLogo from '@/components/headerLogo'
import router from '../router';
import { useEventListener } from '@vueuse/core'

const trackEvent = useEventListener(document, 'click', (e) => {
  const anchor = e.target.closest('a')
    if (e.target.tagName === "A" && e.target.host !== window.location.host) {
        e.stopPropagation()
        e.preventDefault();
        window.open(anchor.href, "_blank")
    }
  }, {capture: true}
)

export default {
  name: 'Header',
  components: { headerLogo },
  data () {
    return {
      imagePath: false,
      trackingSet: '',
      navOpen: false,
      linkTo: false,
    }
  },
  async mounted() {
    // this.logger = new this.$Amplify.Logger(this.$options.name);
  },
  created () {
    this.initGroup()
  },
  methods: {
    async signOut() {
      try {
            await Auth.signOut();
        } catch (error) {
            console.log(error.message);
        }
    },
    initGroup: function() {

      if(this.group){
        if(this.group.groupImage){
          this.imagePath = `groups/${this.group.slug}/${this.group.groupImage}`
        } else {
          this.imagePath = false
        }
        if(this.group.groupLinkTo && this.group.groupLinkTo != ''){
          this.linkTo = this.group.groupLinkTo;
        }
      }
    },
    closeNav: function(){
      this.navOpen = false;
    },
    navigateTo: function (targ) {
      this.navOpen = false;
      router.push(targ)
    }
  },
  computed: {
    ENV() { return AmplifyStore.state.ENV },
    user() { return AmplifyStore.state.user },
    group() { return AmplifyStore.state.private.group },
    userProgress() { return AmplifyStore.state.private.userProgress },
    settings() { return AmplifyStore.state.admin.settings },
    docClient() { return AmplifyStore.state.docClient },
    showNav() {
      if(this.user && this.user.challengeName != 'NEW_PASSWORD_REQUIRED') return true
      else return false
    },
    isAdmin() {
      if(this.user && this.user.attributes) {
        if (this.user.attributes['custom:role'] === 'admin') return true
      }
    },
    isManager() {
      if(this.user && this.user.attributes) {
        if (this.user.attributes['custom:role'] === 'manager') return true
      }
    }
  },
  watch: {
    'group'(val) {
      this.initGroup()
    },
  }
}
</script>

<style scoped>

  .banner {
    text-align: center;
    background-color: var(--error-color);
    color: #fff;
    padding: 0.5rem 0;
    letter-spacing: 0.04em;
  }
</style>
